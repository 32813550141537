import React from "react";
import styles from "./Tokenomic.module.css";

export const Tokenomic = ({sr,topic,percentage}) => {
  return (
    <div className={styles.tokenomics_row}>
      <span>{sr}</span>
      <span>{topic}</span>
      <span>{percentage}%</span>
    </div>
  );
};
