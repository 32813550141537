import React, { useContext, useState } from "react";
import axios from "axios";
import logo1 from "../../assets/images/footer1.png";
import logo2 from "../../assets/images/bunny_sidelogo1.png";
import bunny from "../../assets/images/div.mGoGm2.png";
import social1 from "../../assets/images/login_social/mdi_twitter.png";
import social2 from "../../assets/images/login_social/ic_baseline-telegram.png";
import social3 from "../../assets/images/login_social/ic_baseline-discord.png";
import styles from "./Login.module.css";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import useNotyf from "../../hooks/useNotyf";
import { Loader } from "rsuite";

export const Login = () => {
  const { loginStatus, setloginStatus } = useContext(AppContext);
  const { loading, setloading } = useContext(AppContext);
  let navigate = useNavigate();
  let [email, setemail] = useState("");
  let [password, setpassword] = useState("");
  const notyf = useNotyf();

  function handleClick(handle) {
    switch (handle) {
      case "twitter":
        window.open(
          "https://twitter.com/i/flow/login?redirect_after_login=%2Fdigibunnies",
          "_blank"
        );
        break;
      case "telegram":
        window.open("https://t.me/+NAt-s7uj7GoyOGFl", "_blank");
        break;
      case "discord":
        window.open("https://discord.com/invite/QkHtgTMFUJ", "_blank");
        break;
      default:
        break;
    }
  }

  const handleLogin = async () => {
    setloading(true)
    try {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };
      
      // console.log(email, password);
      
      let bodyContent = JSON.stringify({
        email: email,
        password: password,
      });

      let reqOptions = {
        url: "https://weak-pear-cougar-wig.cyclic.app/api/auth/login",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);
      notyf.success("Login Successful");
      setloginStatus(true);
    //   console.log(response.data);
      localStorage.setItem("user_token", JSON.stringify(response.data.token));
      setemail("");
      setpassword("");
      navigate("/");
      setloading(false)
    } catch (error) {
      setloading(false)
      notyf.error("Something Went Wrong !");
      console.error("An error occurred during login:", error.message);
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.login_header}>
        <img src={logo1} alt="" />
        <img src={logo2} alt="" />
      </div>
      <div className={styles.login_body}>
        <div className={styles.login_containner}>
          <div>
            <img className={styles.login_title} src={bunny} alt="" />
            <div className={styles.login_form}>
              <div className={styles.login_input}>
                <label>Email</label>
                <input
                  type="text"
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                  placeholder="Enter Email ID"
                />
              </div>
              <div className={styles.login_input}>
                <label>Password</label>
                <input
                  type="password"
                  onChange={(e) => setpassword(e.target.value)}
                  value={password}
                  placeholder="Enter Your password"
                />
              </div>
              <div className={styles.login_fp}>
                <p>Forgot Password?</p>
              </div>
              <div className={styles.login_btn}>
                <button onClick={handleLogin}>{loading ? <Loader /> : "Login"}</button>
              </div>
              <div
                onClick={() => navigate("/signup")}
                className={styles.login_register}
              >
                <h5>New User? Register to create a new account</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.login_footer}>
        <img src={social1} alt="" className={styles.socialimg} onClick={() => handleClick("twitter")} />
        <img src={social2} alt="" className={styles.socialimg} onClick={() => handleClick("telegram")} />
        <img src={social3} alt="" className={styles.socialimg} onClick={() => handleClick("discord")} />
      </div>
    </div>
  );
};
