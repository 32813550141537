import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { AppContext } from "../../context/AppContext";
import styles from './Main.module.css';
import presaleAbi from '../../abi/presaleAbi.json';

import bunnylagacy from '../../assets/images/bunnybg.gif';
import bunnylagacy_mobile from '../../assets/images/bunnybg - mobile.gif';
import downarrow from '../../assets/images/LinkButton image.png';
import intro from '../../assets/images/introduction.png';
import idocomingsoon from '../../assets/images/ido_comingsoon.png';
import tokenomics02 from '../../assets/images/tokenomic_AdobeExpress_AdobeExpress.gif';
import bunny1 from '../../assets/images/bunny1.png';
import bunny2 from '../../assets/images/bunny2.png';
import bunny3 from '../../assets/images/bunny3.png';
import bunny4 from '../../assets/images/bunny4.png';
import nftbunny from '../../assets/images/nft_bunny.png';
import nftbunny1 from '../../assets/images/nft_bunny1.png';
import nftbunny2 from '../../assets/images/nft_bunny2.png';
import coll_bunny from '../../assets/images/bunniesC.png';
import coll_bunny1 from '../../assets/images/bunniesC1.png';
import coll_bunny2 from '../../assets/images/bunniesC2.png';
import { Notyf } from 'notyf';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';
import { Contract, ethers, utils } from 'ethers';
import { Loader } from 'rsuite';
import { AppContext } from '../../context/AppContext';
import { Tokenomic } from '../../components/tokenomicRow/Tokenomic';

const presaleAddress = '0x96c8B95f2E50CC95920F675861E10ae2CA57960C';

const Main = () => {
  const { loading, setloading } = useContext(AppContext);
  let { address } = useAccount();

  const notyf = new Notyf({
    duration: 5000,
    position: { x: 'right', y: 'top' },
    dismissible: true,
  });

  const [isclaimable, setisclaimable] = useState(true);
  const imageUrl = window.innerWidth <= 767 ? bunnylagacy_mobile : bunnylagacy;
  const [depositAmt, setDepositAmt] = useState('');
  const [presaleInfo, setPresaleInfo] = useState({
    maxDepositLimit: 0,
    totalRaised: 0,
    userBalance: 0,
    totalClaimable: 0,
  });

  const { data: signer } = useSigner();
  const provider = useProvider();
  const { chain } = useNetwork();

  const presale = new Contract(presaleAddress, presaleAbi, signer ? signer : provider);

  async function fetchPresale() {
    try {
      // if (chain && chain.id !== 1) {
      //   throw new Error("Please switch to ethereum chain");
      // }

      let totalRaised = await provider.getBalance(presaleAddress);
      let walletBalance = await provider.getBalance(address);
      let claimableamount = await presale.tokenClaimable(address);

      // let depositePrice = await presale.ethDepositPrice();
      let depositelimit = await presale.ethDepositLimit();
      setPresaleInfo({
        maxDepositLimit: utils.formatEther(depositelimit),
        totalRaised: utils.formatEther(totalRaised),
        userBalance: utils.formatEther(walletBalance),
        totalClaimable: utils.formatEther(claimableamount),
      });
    } catch (error) {
      console.dir(error);
      notyf.error(error?.reason || error?.message);
    }
  }
  // console.log(presaleInfo);

  async function buyPresale() {
    setloading(true);
    try {
      if (!signer) {
        throw new Error('Connect your wallet');
      }

      if (+depositAmt < 0.1) {
        throw new Error('Deposit Amount should be greater than 0.1');
      } else if (+depositAmt > +presaleInfo.maxDepositLimit) {
        throw new Error('Deposit Amount is above Deposit limit');
      } else if (+depositAmt > +presaleInfo.userBalance) {
        throw new Error('Not Enough Balance');
      }

      const txn = await presale.depositEth({
        value: utils.parseEther(depositAmt),
      });
      const receipt = await txn.wait();
      console.log(receipt);
      notyf.success('Eth Deposit succesfully');
      setDepositAmt('');
      setloading(false);
    } catch (error) {
      setloading(false);
      console.dir(error);
      notyf.error(error?.reason || error?.message);
    }
  }

  const handleIncrement = () => {
    setDepositAmt(+depositAmt + 1);
  };

  const handleDecrement = () => {
    setDepositAmt(+depositAmt - 1);
  };

  const handleInputChange = (event) => {
    let val = event.target.value;
    setDepositAmt(val);
  };

  const [imageOrder, setImageOrder] = useState([nftbunny, nftbunny1, nftbunny2]);
  const [imageOrder2, setImageOrder2] = useState([coll_bunny, coll_bunny1, coll_bunny2]);

  const shuffleImages = () => {
    setImageOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const lastImage = newOrder.pop();
      newOrder.unshift(lastImage);
      return newOrder;
    });
  };

  const shuffleImages2 = () => {
    setImageOrder2((prevOrder) => {
      const newOrder = [...prevOrder];
      const lastImage = newOrder.pop();
      newOrder.unshift(lastImage);
      return newOrder;
    });
  };

  useEffect(() => {
    AOS.init();
    const intervalId = setInterval(shuffleImages, 3000);
    const intervalId2 = setInterval(shuffleImages2, 3000);
    if (address) {
      fetchPresale();
    }

    return () => {
      clearInterval(intervalId); // Cleanup to stop the interval when the component unmounts
      clearInterval(intervalId2); // Cleanup to stop the interval when the component unmounts
    };
  }, [address]);

  return (
    <>
      <Navbar />
      <div className={styles.main} id="home">
        <div className={styles.legacy}>
          <div>
            <div data-aos="fade-up">
              <p>the extraordinary meme token with a play-to-earn ecosystem</p>
            </div>
          </div>
          <img src={imageUrl} alt="" />
          <div className={styles.externalLink}>
            <button onClick={() => window.open('https://www.pinksale.finance/launchpad/0xbDd94F9F4ACB330B0f8a3bE3902bc8EB0e71B7C8?chain=Matic', '_blank')}>Presale</button>
            <button onClick={() => window.open('https://www.bitmart.com/trade/en-US?layout=basic&theme=dark&symbol=DGBN_USDT', '_blank')}>Bitmart</button>
          </div>
        </div>
        <div className={styles.downarrow} style={{ textAlign: 'center' }}>
          <img src={downarrow} alt="" />
        </div>
        <div className={styles.heading} data-aos="fade-left">
          <h1>Introduction</h1>
          <img className={styles.pumping} src={bunny1} alt="" />
        </div>
        <div className={styles.aboutus}>
          <div data-aos="fade-right">
            <img src={intro} className={styles.pumping2} alt="" />
          </div>
          <div>
            <p className={styles.intro_p} data-aos="fade-left">
              Welcome to Digibunnies, where borders fade and possibilities know no bounds. Embrace the power of blockchain and cryptocurrencies, as our platform opens the door to a borderless economy
              that transcends traditional financial limitations.With seamlessly explore our digiwolrds, participate in quizzes and treasure hunts and engage in the vibrant world of cryptos and nfts
              with digibunnies as your trusted partner.
            </p>
          </div>
        </div>
        <div id="ido" className={styles.ido_Div}>
          <div className={styles.idocomingsoon}>
            <div>
              <img src={idocomingsoon} alt="" />
            </div>
          </div>
          <div className={styles.headingido} style={{ filter: 'blur(8px)' }}>
            <h1 data-aos="fade-left">IDO</h1>
            <img src={bunny2} className={styles.pumping} alt="" data-aos="fade-down" />
          </div>
          <div className={styles.ido}>
            <div>
              <p className={styles.intro_p2} style={{ color: 'black' }} data-aos="fade-right">
                We empower you to be at the forefront of financial innovation with our exclusive IDO Access. By joining our IDO Access program, you gain early access to a curated lineup of
                revolutionary crypto projects before they go public. As an early investor, you can take advantage of token pre-sales, enjoy attractive discounts, and have a direct impact on shaping
                the future of these startups.
              </p>
            </div>
            <div data-aos="fade-left">
              <div className={styles.ido_presale}>
                <p className={styles.presale_t1}>PRESALE 1</p>
                <p className={styles.presale_t2}>1 DigiBunnies = 0.657 USDT</p>
                <p className={styles.presale_t3}>Next Stage Price = 0.723 USDT</p>
                <p className={styles.presale_t3}>Sold - $34,56,56,764</p>
                <div className={styles.presale_input}>
                  <input type="text" name="" placeholder="Enter the amount (MATIC)" id="" value={depositAmt} onChange={handleInputChange} />
                  <div>
                    <button onClick={handleIncrement}>+</button>
                    <button onClick={handleDecrement}>-</button>
                  </div>
                </div>
                <div className={styles.presale_btn1}>
                  <button>Minimum Quantity to Buy : {presaleInfo.maxDepositLimit} MATIC</button>
                </div>
                {/* <div className={styles.presale_btn1}>
                  <button>Maximum Quantity of Tokens</button>
                </div> */}
                <div className={styles.presale_btn2}>
                  <button onClick={buyPresale}>{loading ? <Loader /> : 'Buy'}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.tokenomics01_Div} id="tokenomics">
          <div className={styles.headingido} data-aos="fade-left">
            <h1>Tokenomics</h1>
            <img src={bunny3} className={styles.pumping} alt="" />
          </div>
          <div className={styles.tokenomics01}>
            <div data-aos="fade-right">
              <div className={styles.tokenomics_gif}>
                <img src={tokenomics02} width={'100%'} alt="" />
              </div>
            </div>
            <div data-aos="fade-left" className={styles.tokenomics_table}>
              <div className={styles.tokenomics_row_head}>
                <span>No</span>
                <span>Topic</span>
                <span>Percentage</span>
              </div>
              <Tokenomic sr={1} topic="Ecosystem / Treasury" percentage="81" />
              <Tokenomic sr={2} topic="Technical Team" percentage="5" />
              <Tokenomic sr={3} topic="Public Sale" percentage="4" />
              <Tokenomic sr={4} topic="Advisory" percentage="3" />
              <Tokenomic sr={5} topic="Strategic Partners" percentage="3" />
              <Tokenomic sr={6} topic="Marketing / Rewards" percentage="2" />
              <Tokenomic sr={7} topic="Private Sale" percentage="2" />
            </div>
          </div>
        </div>

        <div className={styles.comingsoon_Div} id="roadmap">
          <div className={styles.headingido} data-aos="fade-left" data-aos-duration="500">
            <h1>Coming soon</h1>
            <img src={bunny4} className={styles.pumping} alt="" />
          </div>
          <div className={styles.comingsoon}>
            <div data-aos="fade-right">
              <div>
                <img className={styles.pumping2} src={imageOrder[0]} width={'100%'} alt="" />
                <img className={styles.pumping2} src={imageOrder[1]} width={'100%'} alt="" />
              </div>
              <div>
                <div>
                  <img className={styles.pumping2} src={imageOrder[2]} width={'100%'} alt="" />
                  <p className={styles.nft_p}>NFT Collection</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-left">
              <div>
                <div>
                  <img className={styles.pumping2} src={imageOrder2[0]} width={'100%'} alt="" />
                  <p className={styles.nft_p}>Bunnies Collection Map</p>
                </div>
              </div>
              <div>
                <img className={styles.pumping2} src={imageOrder2[1]} width={'100%'} alt="" />
                <img className={styles.pumping2} src={imageOrder2[2]} width={'100%'} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
