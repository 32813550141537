import React from "react";
import styles from "./Footer.module.css";
import logo from "../../assets/images/footer.png";
import twitter from "../../assets/images/media/twitter.png";
import telegram from "../../assets/images/media/telegram.png";
import mail from "../../assets/images/media/clarity_email-solid.png";
import useNotyf from "../../hooks/useNotyf";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  let notyf = useNotyf();
  function handleClick(handle) {
    switch (handle) {
      case "twitter":
        window.open(
          "https://twitter.com/i/flow/login?redirect_after_login=%2Fdigibunnies",
          "_blank"
        );
        break;
      case "telegram":
        window.open("https://t.me/+NAt-s7uj7GoyOGFl", "_blank");
        break;
      case "discord":
        window.open("https://discord.com/invite/QkHtgTMFUJ", "_blank");
        break;
      case "commingsoon":
        notyf.success("Coming soon");
        break;
      case "home":
        navigate("/");
        break;
      default:
        break;
    }
  }

  return (
    <footer className={styles.footer}>
      <div>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.roadmap}>
        <p>SITEMAP</p>
      </div>
      <div className={styles.roadmap}>
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#ido">IDO</a>
        </li>
        <li>
          <a href="#tokenomics">Tokenomics</a>
        </li>
        <li>
          <a href="#comingsoon">Coming soon</a>
        </li>
      </div>

      <ul className={styles.social}>
        <li>
          <img
            src={twitter}
            alt="twitter"
            className={styles.socialimg}
            onClick={() => handleClick("twitter")}
          />
        </li>
        <li>
          <img
            src={telegram}
            alt="telegram"
            className={styles.socialimg}
            onClick={() => handleClick("telegram")}
          />
        </li>
        <li>
          <img
            src={mail}
            alt="mail"
            className={styles.socialimg}
            onClick={() => handleClick("discord")}
          />
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
