import React, { createContext, useEffect, useState } from "react";

// Create a new context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const [loading, setloading] = useState(false);
  const [loginStatus, setloginStatus] = useState(false);
  const [token, settoken] = useState("");

  // Define the values and functions to be shared
  const contextValue = {
    loading,
    setloading,
    loginStatus,
    setloginStatus,
    token,
    settoken,
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("user_token");
    // console.log(storedToken);
    if (storedToken) {
      settoken(storedToken);
      setloginStatus(true);
    }
  }, []);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
