import React, { useContext, useState } from "react";
import { Dropdown } from "rsuite";
import styles from "./Navbar.module.css";
import logo1 from "../../assets/images/footer.png";
import logo2 from "../../assets/images/bunny_sidelogo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
// import { useAccount } from "wagmi";
// import useNotyf from "../../hooks/useNotyf";
import { AppContext } from "../../context/AppContext";
import "./dropdown.css";

const Navbar = () => {
  const { loading, loginStatus, setLoading, setLoginStatus } =
    useContext(AppContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpenPdf = () => {
    const pdfUrl = "whitepaper.pdf"; // Replace this with your actual PDF file URL
    window.open(pdfUrl, "_blank");
  };
  const handleOpenAudit = () => {
    const auditLink = "https://twitter.com/BlockSafu/status/1661998239783206913?s=20"; // Replace this with your actual PDF file URL
    window.open(auditLink, "_blank");
  };
  // const notyf = useNotyf();

  // const { isDisconnected, isConnected } = useAccount();

  const navOptions = (
    <>
      <li>
        <a href="#home">Home</a>
      </li>
      <li>
        <a href="#ido">IDO</a>
      </li>
      <li>
        <a href="#tokenomics">Tokenomics</a>
      </li>
      <li>
        <a onClick={handleOpenAudit}>Audit</a>
      </li>
      <li>
        <a onClick={handleOpenPdf}>Whitepaper</a>
      </li>
    </>
  );

  return (
    <>
      <div className={styles.container}>
        <ul id={styles.menu} onClick={() => setOpen((prev) => !prev)}>
          {open ? <RxCross2 size={20} /> : <GiHamburgerMenu size={20} />}
        </ul>
        <nav className={styles.navbar}>
          <ul>
            <li onClick={() => navigate("/")} className={styles.logoContainer}>
              <img src={logo1} alt="logo" className={styles.logo1} />
              <img src={logo2} alt="logo" className={styles.logo} />
            </li>
          </ul>
          <ul className={styles.Pagelinks}>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#ido">IDO</a>
            </li>
            <li>
              <a href="#tokenomics">Tokenomics</a>
            </li>
            <li className={styles.dropdown}>
              <Dropdown title="Documents" trigger={["click", "hover"]} size="md">
                <Dropdown.Item onClick={handleOpenPdf}>
                  Whitepaper
                </Dropdown.Item>
                <Dropdown.Item onClick={handleOpenAudit}>Audit</Dropdown.Item>
              </Dropdown>
            </li>
          </ul>
          <ul>
            {loginStatus ? (
              <li className={styles.connectButton}>
                <ConnectButton chainStatus={"none"} />
              </li>
            ) : (
              <li className={styles.LoginButton}>
                <button onClick={() => navigate("/login")}>Login</button>
              </li>
            )}
          </ul>
        </nav>
      </div>

      {open ? (
        <div id={styles.drawerContainer} className={styles.hide}>
          {navOptions}
        </div>
      ) : (
        <div id={styles.drawerContainer_hide} className={styles.hide_draw}>
          {navOptions}
        </div>
      )}
    </>
  );
};

export default Navbar;
