import React, { useContext, useState } from "react";
import logo1 from "../../assets/images/footer1.png";
import logo2 from "../../assets/images/bunny_sidelogo1.png";
import bunny from "../../assets/images/div.mGoGm2.png";
import social1 from "../../assets/images/login_social/mdi_twitter.png";
import social2 from "../../assets/images/login_social/ic_baseline-telegram.png";
import social3 from "../../assets/images/login_social/ic_baseline-discord.png";
import styles from "./Signup.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useNotyf from "../../hooks/useNotyf";
import { Loader } from "rsuite";
import { AppContext } from "../../context/AppContext";
import { MdVisibility } from "react-icons/md";
import { AiFillEyeInvisible } from "react-icons/ai";

export const Signup = () => {
  let navigate = useNavigate();
  const { loading, setloading } = useContext(AppContext);
  let [email, setemail] = useState("");
  let [inputType, setinputType] = useState("password");
  let [password, setpassword] = useState("");
  let [confirmpassword, setconfirmpassword] = useState("");
  const notyf = useNotyf();

  function handleClick(handle) {
    switch (handle) {
      case "twitter":
        window.open(
          "https://twitter.com/i/flow/login?redirect_after_login=%2Fdigibunnies",
          "_blank"
        );
        break;
      case "telegram":
        window.open("https://t.me/+NAt-s7uj7GoyOGFl", "_blank");
        break;
      case "discord":
        window.open("https://discord.com/invite/QkHtgTMFUJ", "_blank");
        break;
      default:
        break;
    }
  }

  const handleSignup = async () => {
    if (password !== confirmpassword) {
      notyf.error("Password does not match");
      setconfirmpassword("");
      return;
    }
    setloading(true);
    try {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      // console.log(email, password);

      let bodyContent = JSON.stringify({
        username: email,
        email: email,
        password: password,
      });

      let reqOptions = {
        url: "https://weak-pear-cougar-wig.cyclic.app/api/auth/signup",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);
      notyf.success("SignUp Successful");
      console.log(response.data);
      setemail("");
      setpassword("");
      setconfirmpassword("");
      navigate("/login");
      setloading(false);
    } catch (error) {
      setloading(false);
      notyf.error("Something Went Wrong !");
    }
  };
  return (
    <div className={styles.login}>
      <div className={styles.login_header}>
        <img src={logo1} alt="" />
        <img src={logo2} alt="" />
      </div>
      <div className={styles.login_body}>
        <div className={styles.login_containner}>
          <div>
            <img className={styles.login_title} src={bunny} alt="" />
            <div className={styles.login_form}>
              <div className={styles.login_input}>
                <label>Email</label>
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="text"
                  placeholder="Enter Email ID"
                />
              </div>
              <div className={styles.login_input}>
                <label>Password</label>
                <input
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  type="password"
                  placeholder="Enter Your password"
                />
              </div>
              <div className={styles.password_input}>
                <label>confirm Password </label>
                <div>
                  <input
                    value={confirmpassword}
                    onChange={(e) => setconfirmpassword(e.target.value)}
                    type={inputType}
                    placeholder="Enter Your password"
                  />
                  <span
                    onClick={() => {
                      inputType === "password"
                        ? setinputType("text")
                        : setinputType("password");
                    }}
                  >
                    {inputType !== "password" ? (
                      <MdVisibility />
                    ) : (
                      <AiFillEyeInvisible />
                    )}
                  </span>
                </div>
              </div>
              <div onClick={handleSignup} className={styles.login_btn}>
                <button>{loading ? <Loader /> : "Signup"}</button>
              </div>
              <div
                onClick={() => navigate("/login")}
                className={styles.login_register}
              >
                <h5>Already User? signin</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.login_footer}>
        <img
          src={social1}
          alt=""
          className={styles.socialimg}
          onClick={() => handleClick("twitter")}
        />
        <img
          src={social2}
          alt=""
          className={styles.socialimg}
          onClick={() => handleClick("telegram")}
        />
        <img
          src={social3}
          alt=""
          className={styles.socialimg}
          onClick={() => handleClick("discord")}
        />
      </div>
    </div>
  );
};
