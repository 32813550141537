import "./App.css";
import "notyf/notyf.min.css";
import "@rainbow-me/rainbowkit/styles.css";
import { Route, Routes } from "react-router-dom";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygon, mainnet, bsc, bscTestnet } from "wagmi/chains";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";
import {
  getDefaultWallets,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { AppProvider } from "./context/AppContext";
import Main from "./pages/Main/Main";
import { Login } from "./pages/Login/Login";
import { Signup } from "./pages/Signup/Signup";

const projectId = process.env.REACT_APP_PROJECT_ID;

const { chains, provider } = configureChains(
  [polygon, mainnet, bsc, bscTestnet],
  [publicProvider()]
);

const { wallets } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId,
  chains,
});

const connectors = connectorsForWallets([...wallets]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function App() {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          theme={darkTheme({
            accentColor: "#332d53",
            accentColorForeground: "white",
            overlayBlur: "small",
            borderRadius: "medium",
          })}
        >
          <AppProvider>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </AppProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
